//  SPO Specific Styles
//  -----------------------------
//
//
//  Table of Contents:
//
//   1. Settings
//   2. Global Styles
//   3. Home Page
//   4. Secondary Page
 

//   1.  Settings
//   ---------------------

// Colors (set in _settings.scss)

// $dark-gray: #373737;
// $lightest_gray: #fcfcfc;
// $blue: #5cbe94;
// $red: #d13f32;
// $deep-red: #ad1e11;
// $cream: #f9f3e4;
// $tan: #ebdebf;

//temporary for alerting missing links
.pink{
  color: magenta !important;
}
//temporary for moving sections 
.gray {
  h2, h3,h4, p, ul, li {
    color: #b1b0b0 !important;
  }
  .callout {
     background-color: #e4e4e4 !important;
     border-color: #e4e4e4 !important;
  }
}


$transition: all 0.2s ease-in-out;

$topbar-unstack-breakpoint: 900px;

//   2.  Global Styles
//   ---------------------
ol ul {
  list-style-type: initial;
}

strong em {
  font-weight: bold;
}
.blue {
  color: $blue !important;
}
.red {
  color: $red !important;
}
.max-width-900 {
  max-width: 875px;
  margin: 0 auto !important;
}
:focus {
    outline: $gray auto 5px;
    outline-color: $gray;
    outline-style: auto;
    outline-width: 5px;
}
.main-content {
  h2 {
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 24px;
  }
  div.content > h2:first-child {
    margin-top: 0;
  }
  h3 {
    text-transform: none;
    font-size: rem-calc(20);
    margin-top: 20px;
    color: $dark-gray;
    // text-transform: uppercase;
    // font-size: rem-calc(20);
    // margin-top: 30px;
  }
  h4 {
    text-transform: none;
    font-family: $body-font-family;
    font-weight: bold;
    font-size: rem-calc(17);
    margin-top: 20px;
    color: $dark-gray;
  }
  h5 {
    font-family: $body-font-family;
    font-weight: 700;
    color: $dark-gray;
    font-size: rem-calc(17);
  }
  .content {
    max-width: 768px;
  }
  ul:not(.accordion) li a {
    text-decoration: underline;
    color: $blue-link;
  }
  ul {
    // padding-left: 20px;
  }
  ul.address li {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 10px;
  }
  h3.bar {
    background-color: $tan;
    padding: 10px;
  }
  .download-form {
    &:before {
    content:'\f15b';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-right: 5px;
    }
  }
  .download-form {
    position: relative;
  }
  .download-list {
    list-style-position: outside;
 
    .download-form {
        &:before {
          color: $gray;
        content:'\f15b';
        display: inline-block;
        position: absolute;
        left: -20px;
        right: 0;
        top: 3px;

        }
      }
  }
  .callout {
    background-color: $tan;
    padding: 30px;
    border: 0;
    h3.dark{
      color: $dark-gray;
    }
    &.blue {
      background-color: $red;
      .intro-text{
        color: white;
        
      }
      border: none;
    }
  }
  .callout-red {
    background-color: white;
    color: $red;
    padding: 30px;
    border: 1px solid $tan;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .callout-white {
    background-color: white;
    padding: 30px;
    border: 1px solid $tan;
    p:last-child {
      margin-bottom: 0;
    }
    &>h3{
      margin-top: 0;
    }
  }
  .intro-text {
    font-size: rem-calc(20);
    color: $gray;
    text-align: left;
    font-weight: 600;
    line-height: rem-calc(28);
    a {
      color: $gray;
    }
  }
  .tip-text {
    font-size: rem-calc(18);
  }
  p a, ol li a {
    color: $blue-link;
    text-decoration: underline;
  }
}
.faq .content, .language-page .content {
  max-width: 100%;
}


//Fix Flash of unstyled content
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
// Attention Bar
.attention-bar {
  background-color: $blue;
  text-align: center;
  font-weight: bold;
  p {
    padding: 10px;
    margin: 0;
    font-size: rem-calc(14);
     @include breakpoint(medium down){
      padding-top: 40px;
     }
  }
  a {
    text-decoration: underline;
  }
  span{
    color: white;
  }
}
.attention-bar.temporary{
  background-color: transparent;
  span{
    color: $dark-gray;
  }
}


// Top Bar

#menu-wrapper {
  background-color: $dark-gray;
}
.language-row {
  background-color: #fff;
}
.top-bar#main-menu {

// only the desktop menu
  @include breakpoint(large) {
    .main-menu .menu-item a {
      padding: 0 0 0 15px;
      max-width: 180px;
    }

    .menu-item a, .is-drilldown-submenu a  {
      border-left: 2px solid $yellow;
      padding: 0px;
    }
    .top-bar-right {
      float: left;
      width: 59%;
    }
    .top-bar-left {
      float: left;
      width: 40%;
    }
    
    .top-bar-right ul {
      margin-top: 22px; 
      margin-bottom: 5px
    }

    li.menu-item {
      .fa.fa-chevron-down {
        font-size: 12px;
      }
    }

    li.menu-item a{
      padding-right: 60px;
      line-height: rem-calc(20);

    }

    li.menu-item:nth-child(0n + 1){ 
      width: 240px;
      a {
        // max-width: 150px;
      }
    }

    li.menu-item:nth-child(0n + 2) {
      width: 285px;
      a {
        max-width: 214px;
      }
    }

    li.menu-item:nth-child(0n + 3){
      width: 290px;
      a {
        max-width: 170px;
      }
    }
  }
// narrower desktop menu
  @media screen and (max-width: 1160px){
    
    li.menu-item:nth-child(0n + 1){ 
      width: 180px;
    }
    
    li.menu-item:nth-child(0n + 2) {
      width: 250px;
    }
    
    li.menu-item:nth-child(0n + 3){
      width: 250px;
    }
  }

  @media screen and (max-width: 1126px){
     .drilldown li.menu-item, .drilldown li.menu-item a {
      width: auto;
    }
  }

  .top-bar-left {
    padding-top:8px;
  }

  .top-bar-right li {
    text-align:left;
  }

  .top-bar-right  li.menu-item {
    // padding-bottom: 11px;
    vertical-align: top;
  }

  .menu-item a, .is-drilldown-submenu a  {
    font-family: $header-font-family;
    color: white;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
  }

  .is-drilldown-submenu a {
    border: none;
  }

  .js-drilldown-back {
    margin-bottom: 0px;
  }

  .js-drilldown-back::before {
    border-color: transparent white transparent transparent;
  }
  
  .dropdown.menu .has-submenu.is-right-arrow > a::after {
    border: none;
  }

  .language {
    display: none;
    visibility: hidden;
    text-align: right;
    .dropdown-pane {
      background-color: white;
    }
    ul {
      margin: 0;
    }
  }

  .menu-text {
    color: #fff;
    padding: 0;
    
     @include breakpoint(medium down) {
      display: none !important;
    }
  }
  

  ul.js-dropdown-active, .dropdown.menu .submenu {
    padding: 15px;
    margin-top: 7px;
    border: 1px solid $dark-gray;
  }

  .js-dropdown-active li {
      margin: 15px 0;

  }

  .is-dropdown-submenu-item a {
    font-family: $body-font-family;
    font-size: rem-calc(15);
    border: none; 
    color: $dark-gray !important;  


    &:hover {
      color: $blue;
      text-decoration: underline;
    } 
  }
}
/// Mobile Menu
@include breakpoint(medium down) {
  // #menu-wrapper .top-bar-right {
  //   float: none;
  // }
  // 
  .top-bar#main-menu .is-submenu-item.is-drilldown-submenu-item a{
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    font-size: rem-calc(16);
    line-height: rem-calc(18);
    max-width: 90%;
  }
  .logo img {
    width: 200px;
  }
  .language {
    margin-left: 9px;
    margin-top: 5px;
    display: none;
  }
  .top-bar#main-menu ul.mobile-languages {
    margin-top: 5px;
    border-top: 1px solid $gray;
    padding-top: 8px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .top-bar#main-menu .mobile-languages .menu-item a{
    font-size: rem-calc(14px);
    color: $gray;
  }
  .js-drilldown-back {
    margin-bottom: 5px;
  }
  .is-drilldown-submenu {
    background-color: $dark-gray !important;
  }
  // .submenu .is-submenu-item.is-drilldown-submenu-item a{
  //       padding: 5px inherit;
  // }
  .js-drilldown-back > a::before {
    // content: '';
    // display: block;
    // width: 0;
    // height: 0;
    // border: inset 6px;
    border-color: transparent #fff transparent transparent;
    // border-right-style: solid;
    // border-left-width: 0;
    // display: inline-block;
    // vertical-align: middle;
    // margin-right: 0.75rem;
}
}
 @include breakpoint(small only){
  .top-bar .menu.expanded > li {
      width: 100% !important;
  }
}
// .top-bar::after {
//     content: '';
//     display: inline;
// }
// // Mobile Hamburger Menu
// // 

#menu-wrapper .title-bar {
  background-color: $dark-gray !important;

  .logo {
    float: left;
  }
  .menu-icon {
    margin-top: 12px;
    width: 33px;
    height: 22px;
  }
  .menu-icon::after {
    box-shadow: 0 10px 0 white, 0 20px 0 white;
  }

  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 10px 0 #cacaca, 0 20px 0 #cacaca;
  }
  .logo {
    margin: 5px 10px;
  }

}
//Languages
.top-bar#sub-menu {
  float: right;
  padding: 0;
  ul {
    padding: 6px 0;
  }
  li {
    margin-left: 5px;
    display: inline-block;
  }
  a {
    color: inherit;
    font-size: rem-calc(12);
    padding: 5px 9px;
  }
  li.spanish {
    border: 1px solid $dark-gray;
  }
  @include breakpoint(medium down) {
    display: none;
  }

}

//Accordion
//
.accordion {
  background-color: white;
}
.accordion-title {
  padding-left: 30px;
  font-size: rem-calc(15);
  font-weight: 600;
  line-height: rem-calc(19);
}
.accordion-title {
    font-weight: bold;
    strong {
      // color: $blue;
      font-size: 18px;
  }
  &:after {
    content: "\f0da";
    font-family: "FontAwesome";
    padding-left: rem-calc(5);
    right: auto;
    right: 5px;
    font-size: 16px;
    float: right;
    color: $gray;
  }
  &:before {
    content: "";
    // font-family: "FontAwesome";
    // padding-left: rem-calc(5);
    // right: auto;
    // left: 5px;
    // font-size: 16px;
    // color:
    // $gray;
  }
}
li.accordion-item {
  margin-bottom: 0;
}
li.is-active .accordion-title:after {
    content:  "\f0d7";
}
li.is-active .accordion-title:before {
    content:  "";
}
.accordion ul, .accordion ol {
  padding-left: 20px;
}
.accordion ul li {
  list-style-type: disc;
}
.accordion ol li {
  list-style-type: decimal;
}
.accordion-content {
  padding: 15px 40px;
}
.accordion-content a {
  color: $blue;
  text-decoration: underline;
}
.accordion-content p.red {
  color: red;
}

//   Buttons
// 
.safety-wrap {
  position: fixed;
  top: 0;
  z-index: 1001;
  line-height: 0;
  display: none;
  @include breakpoint(medium down){
    // background-color: $dark-gray;
    width: 100%;
  }

}
#safety-button { 
  right: 10%;
  font-weight: 700;
  color: white;
  font-size: rem-calc(14);
  max-height: 28px;
  display: inline-block;
  div.button {
    color:white;
  }
  .x {
    display: inline-block;
    float: right;
    text-align: center;
    padding-top: 7px;
    width: 28px;
    height: 28px;
    background-color: $red;
    color: white;
  }
}

.button {
  font-weight: 600;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: rem-calc(1);
}

.button.alert {
  margin: 0;
  color: white;
}

.button.hollow.white {
  @include button-hollow-style(#fff);
}

.button.hollow.white:hover, .button.hollow.white:focus {
    border-color: white;
    color: white;
    background-color:rgba(10,140,130,1);
}
//  Dropdown Menus
//  
//  What Is DVPO intro
.what-is {
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;

  h2 {
    color: $gray;
    text-transform: none;
    font-size: rem-calc(37);
    @include breakpoint(medium down){
      line-height: rem-calc(32);
      font-size: rem-calc(28);
    }
  }
  p{
    margin-bottom: 5px;
    font-size: rem-calc(20);
    line-height: rem-calc(28);
    @include breakpoint(medium down){
      line-height: rem-calc(20);
      font-size: rem-calc(16);
    }

  }
  a {
    text-decoration: underline;
  }
  span {
    padding-left: 5px;

  }
}

//    Panels
// 
.dropdown-button {
  position: relative;
  padding-left: 30px;
}
.dropdown-button {
   &:before {
    position: absolute;
    content: "\f0da";
    font-family: "FontAwesome";
    padding-left: rem-calc(5);
    color: white;
    right: auto;
    left: 5px;
    font-size: 16px;
  }
}
.dropdown-button.hover{
   &:before {
    content:  "\f0d7"
  }
}
.main-content .dropdown-pane.is-open{
  background-color: #fff;
  :focus {
    outline: none;
    outline-color: white;
    outline-style: auto;
    outline-width: 5px;
    text-decoration: underline;
  }
  li {
    // border-bottom: 1px solid $gray;
    margin: 0;
  }
  a {
    padding: 6px 0px;
    display: block;
    text-decoration: none;
  }
}
.get_order .dropdown-pane {
  background-color: $lightest-gray;
  padding: 0;
  ul {
    list-style-type: none;
    margin: 0;
  }
  li {
    padding: 8px;
    border-bottom: 1px solid $light-gray;
  }
  a {
    line-height: rem-calc(18) !important;
  }
}
.panels {
  margin-top: 40px;
  margin-top: 50px;
  &.second-row {
    margin-top: 0px;
    margin-bottom: 40px;
  }
  &.single {
    .panel {
      height: auto;
    }
  }
}
.what-is.panels {
  h3 {
    margin-top: 10px;
  }
}
.panels .column {
  margin: 15px 0;
}
.large-9 .panels{
  margin-top: 0;
  h3 {
    font-size: rem-calc(24) !important;
  }
}

.panel {
  position: relative;
  text-align: center;
  background-color: #fff;
  border-left: 12px solid $gray;
  padding: 30px 30px 50px 60px;
  height: 270px;
  margin-top: 0;
  
  img {
    display: inline-block;
    margin: 0 auto 20px auto;
  }

  h3, p {
    text-align: left;
  }

  h3 {
    font-size: rem-calc(37);
    line-height: rem-calc(45);
    text-transform: none;
    margin-top: 0;
    color: $blue;
  }
  p {
    @extend .show-for-medium;
  }

  .button {
    position: absolute;
    bottom: 10px;
    left: 60px;
    padding: 12px 18px;
    @extend .show-for-medium;
  }
}
// Welcome
// 
.welcome {
  padding: 40px 0;
  h3 {
    color: $gray;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    margin-bottom: 25px;
    margin-top: 0;
    &:after {
      content: '';
      height: 10px;
      width: 130px;
      background-color: $gray;
      display: block;
      margin-top: 7px;
    }
  }
  .welcome-text {
    padding-left: 60px;
    padding-right: 40px;
  }
  .intro {
    color: $blue;
    font-weight: 600;
    font-size: rem-calc(25);
    line-height: rem-calc(30);
  }
  .packet {
    border: 1px solid $tan;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    margin: 40px 0;


    p {
      margin: 0;
      display: inline-block;
      font-weight: bold;
    }
    a{
      margin: 0;
      margin-left: 10px;
      color: #6b615e;
      text-decoration: none;
      i {
        margin-right: 5px;
      }
      @include breakpoint (small only){
        margin-top: 10px;
      }
    }
    // a {
    //   text-transform: uppercase;
    //   font-weight: bold;
    //   background-color: $yellow;
    //   padding: 10px;
    //   // color: $dark-gray;
    //   text-decoration: none;
    // }
  }  
}

@include breakpoint(medium only){
  .panel {
    height: 250px;
    padding-left: 30px;
  }
  .panel p {
    font-size: 15px;
  }

  .panel h3 {
    font-size: rem-calc(24);
    line-height: rem-calc(28);

  }
  .panel .button {
    left: 30px;
  }

}
@media (max-width: 767px){
  .panel {
    height: 550px;
  }
  .panel h3 {
    font-size: 20px;
  }
  .panel p {
    font-size: 12px;
  }
}
@include breakpoint(small only){
  .what-is {
    padding-bottom: 20px;
    max-width: 5500px;

  }
  .us-graphic {
    text-align: center;
    margin-bottom: 30px;
  }
  .panels {
    margin-top: 0;
  }

  .panels .column {
    margin: 0;
    padding: 0;
    background-color: white;
  }

  .panel {
    height: auto;
    min-height: 20px;
    text-align: center;
    border-left: none;
    border-bottom: 8px solid $cream;
    padding: 30px;
  }
  .panel h3 {
    text-align: center;
    font-size: rem-calc(26);
    margin: 0;    
  }
}

//Next steps
.next-step {
    border: 1px solid #ebdebf;
    padding: 20px 20px 20px;
    display: flex;
    align-items: center;
    @include breakpoint(small only){
      display: block;
      float: left;
      text-align: center;
    }
  .icon {
    text-align: center;
    @include breakpoint(medium only){
      
    }
  }
}


//   3.  Home Page
//   ---------------------

body {
  background-color: #f9f3e4;
  min-height: 100%;
}

.hero {
  background: #3b9dc0 url('../img/hero_background.jpg') repeat-y center bottom;
  height: 320px;
    // height: 100%;
  // .row {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  // }
  .row.title {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
  }
  .intro {
    max-width: 550px;
  }

  h1 {
    color: white;
    font-size: rem-calc(73);
    line-height: rem-calc(80);
    @include breakpoint(small only){
      text-align: center;
      font-size: rem-calc(50);
      line-height: rem-calc(70);
    }
  }

  p {
    font-size: rem-calc(20);
    line-height: rem-calc(29);
    font-weight: 600;
    max-width: 525px;
    margin: 0 auto;
    @include breakpoint(small only){
      text-align: center;
      font-size: rem-calc(20);
      line-height: rem-calc(29);

    }
  
  }

  @include breakpoint(medium down){
    height: auto;
    padding: 40px 10px;
  }
}
.covid{
  text-align: center;
  background-color: $red;
  padding: 15px 0;
  h2 {
    color: white;
    font-size: 2rem;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .doc-links{
    
  }
 
  ul {
    list-style: none;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 25px;
  }
  li {
    margin-bottom: 10px;
  }
  .bold-item {
    font-weight: bold;
  }
  li, li a {
    line-height: 1.3em;
    color: white;
  }
  li.list-title{
    font-weight: bold;
  }
  a.forms-button {
    margin: 10px;
    color: white !important;
    padding: 4px;
    display: inline-block;
    width: 100%;
    max-width: 180px;
    text-decoration: none;
    font-size: 16px;
    border: 1px solid white;
  }
}

.band.danger {
  background: #c33b2f url('../img/red_tile.jpg') repeat center top;
  color: white;
  padding: 50px 0;

 
  .immediate, .staying-safe {
    text-align: center;
    margin-bottom: 30px;
    
  }

  .immediate h3, .staying-safe h3 {
    padding: 20px 0;
    color: white;
  }
  .staying-safe a {
    color: white;
  }

  .immediate .call {
    font-size: rem-calc(40);
  }

  .immediate:before, .immediate:after {
    content:' ';
    display: block;
    border-top: 1px solid white;
    width: 20%;
    margin: 0 auto;
  }

}

.about {
    // text-align: center;
    // margin: 80px 0 0;

   .title {
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px solid $dark-gray;
    color: $dark-gray;
    font-size: rem-calc(20);
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  
  p {
    text-align: left;
  }
}
.contact-info {
  @extend .about;
  p{
  text-align: center;
  }
  margin: 20px 0;
}
// Footer
//


footer {
  background-color: $blue;
  margin-top: 50px;
  padding: 30px 0 15px 0;

  h4 {
    font-family: $body-font-family;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem-calc(16);
    letter-spacing: .05em;
  }
  h5{
    font-family: $body-font-family;
    color: inherit;
    font-weight: bolder;
    font-size: rem-calc(20);
    margin-bottom: 0;
  }
  .email {
    margin-top: 15px;
    a {
    color: inherit;
    }// display: flex;
  }
  .share {
   margin: 0; 
  }
  p, li {
    font-size: rem-calc(15);
  }
  ul {
    margin-left: 0;
  }
  li {
    list-style-type: none;
  }
  li a:hover {
    text-decoration: underline;
  }
  .copyright{
    margin-top: 30px;
  }
  .copyright img{
    margin: 8px 0;
  }
  p.more-info a{
    text-decoration: underline;
  }
  .legal {
    font-size: rem-calc(12);
  }

  @include breakpoint(small only){
    text-align: center;
    .email, .share {
      display: inline-block;
    }
  }
}

//   4.  Secondary Page(s)
//   ---------------------
.content ol, .content ul:not(.accordion){
  margin-left: rem-calc(40);
}

.not-found .title-container {
    margin: 0 auto !important;
  }
.not-found.title-wrapper {
   h1 {
    display: table-cell;
    vertical-align: middle;
    color: white;
    font-size: rem-calc(60);
    line-height: rem-calc(60);
  }
}
.not-found .content {
  margin: 0 auto;
  text-align: center;
}
.title-wrapper {
  background-color: $blue;
  margin-bottom: 30px;
  .title-band{
    padding: 20px 0 20px;
  }
  .title-container{    
  }
  .columns {
    text-align: center;
    @include breakpoint(large up)
    {
      text-align: left;
    }
  }
  h1 {
    color: white;
    font-size: rem-calc(40);
    line-height: rem-calc(50);
  }
  span {
    width: 100px;
    height: 100px;
    float: left;  
    margin-right: 30px;
  }
  &.am span {
    content: url('../img/SVG_icons-01.svg');
  }
  &.what span {
    content: url('../img/SVG_icons-02.svg');
  }
  &.should span {
    content: url('../img/SVG_icons-03.svg');
  }
  &.can span {
    content: url('../img/SVG_icons-04.svg');
  }
  &.how span {
    content: url('../img/SVG_icons-05.svg');
  }
  &.now span {
    content: url('../img/SVG_icons-06.svg');
  }
  &.resources {

    span {
      display: none;
      visibility: hidden;
    }
  }
}
.locations-page .content ul.address {
    margin-left: 0 !important;
}
.location-maps {
  h3 {
    margin-top: 0;
  }
}
#map-seattle.map {
  width: 100%;
  height: 400px;
}
#map-kent.map{
  width: 100%;
  height: 400px;
}

// #map-seattle
#safety-tip-resources .accordion h5 {
  margin-bottom: 0;
}
h2.safety-tips {
  margin-top: 0;
}

.other-page ul.order-options {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 30px;
  li {
    padding: 20px;
    border: 1px solid $tan;
    background-color: white;
    text-align: center;
  }
  a {
    text-decoration: none !important;
    font-weight: bold;
  }
  i {
    padding-left: 5px;
    font-size: 12px;
    color: $gray;
  }
}

// Resources
.resources-page .content#resources{
  p, ul, li a {
    font-size: rem-calc(15);
    line-height: rem-calc(22);
  }
  ul {
       list-style: none;
       margin-left: 0;

  }
  li {
    margin-bottom: 0;
  }
  h3 {
    color: $blue;
    border-top: 5px solid $gray;
    padding-top: 15px;
    font-size: rem-calc(22);
  }
  h4 {
    font-size: rem-calc(15);
    margin-bottom: 0;

  }
  strong {
    color:#a49f96;
    text-transform: uppercase;
    font-size: rem-calc(14);
    padding-top: 10px;
    display: inline-block;
    line-height: rem-calc(18);
  }
  small {
    display: inline-block;
  }
  .large-6 > h3 {
    margin-top: 0;
  }
  .large-6 ul:last-child {
    margin-bottom: 20px;
  }
  .large-6 h3:first-child {
      border-top: none !important;
      margin-top: 0;
      padding-top: 0;
    }
}
// Side Menu
// 
//you are here highlights
.about-page .about,
.advocacy-page .advocacy,
.locations-page .locations,
.firearms-page .firearms,
.should-page .should,
.safe-page .safe,
.supervised-page .supervised,
.can-page .can,
.other-page .other,
.how-page .how,
.process-page .process,
.safety-page .safety,
.now-page .now,
.safety-planning-page .safety-planning,
.internet-page .internet,
.modification-page .modification,
.children-page .children,
.resources-page .resources,
.faqs-page .faqs,
.downloadable-forms-page .downloads

 {
  color: $blue !important;
}
.supervised-page .supervised {
  color: $blue !important;
}
.side-menu {
  background-color: white;
  padding: 10px 20px;
  max-width: 220px;
  margin-bottom: 30px;
  ul {
    font-family: $header-font-family;
    font-size: rem-calc(15);
    list-style-type: none;
    margin: 0;
  }
  li {
    padding-left: 20px;

  }
  li:first-child {
    padding-left: 0;
  }
  li a {
    display: block;
    margin: 15px 0;
    color: $dark-gray !important;
    text-decoration: none !important;
  }
  li a:hover, li a:active {
    color: $blue;
  }
  li:after {
    content: ' ';
    display: block;
    border-bottom: 1px solid $dark-gray;
    width: 100px;
  }
  li:last-child:after {
    border-bottom: none;
  }
  @include breakpoint(medium down){
    padding: 5px;
    width: 100%;
    min-width: 100%;
    float: left;
    
    li {
      float: left;
      width: 120px;
    }
  }
}